@charset "utf-8";
/* ============================================================ */
/* index.html */
/* ============================================================ */
#index {
	.visual {
		position: relative;
		width: 100%;
		
		&__img {
			height: 0;
			padding-bottom: 106.25%;
			overflow: hidden;

			img {
				width: auto;
				height: 100%;
				max-width: 100%;
				max-height: 100%;
			}
		}

		&__overlay {
			position: absolute;
			top: 0;
			right: 0;
		}
	}
	
	.purpose {
		&__item {
			position: relative;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			color: $white;
			height: 110px;
			text-align: center;
			line-height: 1.2;
			transform: rotate(0.05deg);
			text-shadow: 0px 0px 5px $baseColor;
			font-size: 2.0rem;
			
			
			&:hover {
				opacity: 0.7;
			}
		}
		.banquet {
			background:url(/common/img/index/pic_index_01.png) no-repeat;
			background-size: cover;
		}
		.group {
			background:url(/common/img/index/pic_index_02.png) no-repeat;
			background-size: cover;
		}
		.person {
			background:url(/common/img/index/pic_index_03.png) no-repeat;
			background-size: cover;
		}
		.party {
			background:url(/common/img/index/pic_index_04.png) no-repeat;
			background-size: cover;
		}
		.judge {
			background:url(/common/img/index/pic_index_05.png) no-repeat;
			background-size: cover;
		}
		.okuisome {
			background:url(/common/img/index/pic_index_06.png) no-repeat;
			background-size: cover;
		}
		.women {
			background:url(/common/img/index/pic_index_07.png) no-repeat;
			background-size: cover;
		}
	}
	
	.greeting {
		&__bg {
			background: url(/common/img/index/bg_pattern_01.png) top center no-repeat;
		}
		&__ttl {
			text-align: center;
			color: $baseColor;
			font-size: 2.8rem;
			font-weight: bold;
			line-height: 1.4;
			margin-bottom: 10px;
			padding-top: 50px;
			color: $red;
			&:before {
				content: url(/common/img/index/bg_title_01.png);
				margin-right: 0.5em;
			}
			&:after {
				content: url(/common/img/index/bg_title_02.png);
				margin-left: 0.5em;
			}
		}
	}
	.contents01 {
		margin: 0;
		&__item {
			margin-right: 2px;
			margin-left: 2px;
			width: 100%;
			text-align: center;
			color: $white;
			text-shadow: 0px 0px 10px $baseColor;
			
			&:first-child {
				margin-left: 0;
			}
			
			&:last-child {
				margin-right: 0;
			}
			
			p {
				text-shadow: 0px 0px 5px $baseColor;
			}
			
			a {
				position: relative;
				display: block;
				padding: 30px 0;
				
				.btn {
					text-shadow: none;
				}
			}
			&--pattern01 {
				background:url(/common/img/index/bg_index_01.png) no-repeat;
				background-size: cover;
			}
			&--pattern02 {
				background:url(/common/img/index/bg_index_02.png) no-repeat;
				background-size: cover;
			}
			&--pattern03 {
				background:url(/common/img/index/bg_index_03.png) no-repeat;
				background-size: cover;
			}
		}
	}
	
	.ttl02 {
		font-size: 2.3rem;
	}
	
	.budget {
		.btn {
			font-size: 3.0rem;
		}
	}
	
	.news {
		.ttl04 {
			font-size: 2.6rem;
			border-bottom: 1px dotted #c7b299;
			margin: 0;
			
			&::after {
				left: 0;
				bottom: 0;
				width: 0;
				border-bottom: none;
			}
			
			&:before {
				content: url(/common/img/index/ico_01.png);
				margin-right: 0.3em;
				top: 5px;
			}
		}
		
		&__more {
			@include clearfix;
			border-bottom: 1px dotted #c7b299;
			a {
				float: right;
				font-size: 1.4rem;
				background: none;
				color: $red;
				padding: 3px 10px;
			}
		}
	
		&__item {
			border-bottom: 1px dotted #c7b299;
			padding: 20px 0;
			
			a {
				color: $textColor;
			}
			
			&--time {
				color: $red;
				width: 90px;
			}
			&--title {
				margin-left: 100px;
				a {
					text-decoration: underline;
				}
			}
		}
	
		&__sns {
			&__item {
				line-height: 67px;
				text-align: center;
				font-size: 1.8rem;
				width: 320px;
				margin: 0 auto 15px;
				
				a {
					width: 100%;
					height: 100%;
					display: block;
					color: $white;
					&:hover {
						opacity: 0.7;
					}
				}
							
				&--line {
					background:url(/common/img/index/btn_index_01.png) top left no-repeat;
					background-size: contain;
				}
				&--inst {
					background:url(/common/img/index/btn_index_02.png) top right no-repeat;
					background-size: contain;
				}
			}
		}
	}
	
	.banner {
		&__item {
			line-height: 67px;
			text-align: left;
			font-size: 1.8rem;
			width: 320px;
			margin: 0 auto 15px;	
			
			a {
				width: 100%;
				height: 100%;
				display: block;
				color: $white;
				.banner__item__text {
					padding-left: 20px;
				}
				&:hover {
					opacity: 0.7;
				}
			}	

			&--faq {
				background:url(/common/img/index/btn_index_03.png) top left no-repeat;
				background-size: contain;
			}
			&--campany {
				background:url(/common/img/index/btn_index_04.png) top right no-repeat;
				background-size: contain;
			}
		}
	}
}