@charset "utf-8";
/* --------------------------------------------------- */
/* contact */
/* --------------------------------------------------- */
.contact {
	.ttl03 {
		background: url(/common/img/common/bg_red.png) repeat;
		padding: 0;
		line-height: 60px;
		text-align: left;
		font-size: 3.0rem;
		
		.icon-phone {
			font-size: 3.0rem;
			border-right: 1px solid $white;
			padding: 15px 15px 16px 30px;
		}
	}
}
