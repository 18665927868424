@charset "utf-8";

@import "_reset";
@import "_icon";
@import "_mixin";

@media print, screen and (min-width: 767.5px) {
	@import "pc/_base";
	@import "pc/_header";
	@import "pc/_contents";
	@import "pc/_footer";
	@import "pc/_index";
	@import "pc/_company";
	@import "pc/_faq";
	@import "pc/_points";
	@import "pc/_news";
}

@media screen and (max-width: 767.499px) {
	@import "sp/_base";
	@import "sp/_header";
	@import "sp/_contents";
	@import "sp/_footer";
	@import "sp/_index";
	@import "sp/_contact";
	@import "sp/_company";
	@import "sp/_faq";
	@import "sp/_points";
	@import "sp/_news";
}
@import "_utility";



