@charset "utf-8";
/* ============================================================ */
/* 基本設定 */
/* ============================================================ */
html {
	overflow-y: scroll;
	font-family: $ryumin;
}
h1,h2,h3,h4,h5,ul,li,table {
	font-family: $ryumin;
	transform: rotate(0.05deg);
}
@supports (-ms-ime-align:auto) {
	p {
		font-family: $ryumin;
		transform: rotate(0.05deg);
	} 
}

@media screen and (min-width:  767.5px) and (max-width:1024px) {
	html {
		zoom: 0.7;
		font-size: 43.75%;
	}
}

.wrap {
	color: $textColor;
	font-size: $s;
	line-height: 1.8;
}

.inner {
	max-width: 1040px;
	margin: 0 auto;
	padding: 50px 20px 50px;
}

.sp-view {
	display: none !important;
}

a {
	color: $red;
}


/* --------------------------------------------------- */
/* topic-path */
/* --------------------------------------------------- */
.topic-path {
	padding: 12px 0;
	font-size: $xxs;
	line-height: 1.4;
	transform: rotate(0.05deg);
	
	&__inner {
		display: flex;
		flex-wrap: wrap;
		max-width: 1000px;
		margin: 0 auto;
		padding: 0 20px;
	}
	&__item {
		
		&:not(:last-child) {
			&::after {
				margin: 0 10px;
				content: "\f105";
				font-family: 'icomoon';
			}
		}
	}
}


/* --------------------------------------------------- */
/* pagetop */
/* --------------------------------------------------- */
.pagetop {
	position: fixed;
	right: 50px;
	bottom: 50px;

	
	&__link {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 50px;
		height: 50px;
		background: rgba($red, 0.9);
		color: $white;
		font-size: $xxxl;
		@include transition;
		
		&:hover {
			@include opacity;
		}
	}
}