@charset "utf-8";
/* ============================================================ */
/* 基本設定 */
/* ============================================================ */
body {
	background: $white;
	font-family: $ryumin;
}
h1,h2,h3,h4,h5 {
	font-family: $ryumin;
	transform: rotate(0.05deg);
}

.wrap {
	color: $textColor;
	font-size: $s;
	line-height: 1.8;
}

.inner {
	max-width: 100%;
	padding: 30px 15px;
	box-sizing: border-box;
}

.pc-view {
	display: none !important;
}

a {
	color: $red;
}



/* --------------------------------------------------- */
/* topic-path */
/* --------------------------------------------------- */
.topic-path {
	padding: 10px 0;
	font-size: $xxxs;
	line-height: 1.4;
	transform: rotate(0.05deg);
	
	&__inner {
		display: flex;
		flex-wrap: wrap;
		padding: 0 15px;
	}
	&__item {
		
		&:not(:last-child) {
			&::after {
				margin: 0 10px;
				content: "\f105";
				font-family: 'icomoon';
			}
		}
	}
}


/* --------------------------------------------------- */
/* pagetop */
/* --------------------------------------------------- */
.pagetop {
	position: fixed;
	right: 15px;
	bottom: 15px;
	
	&__link {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 40px;
		height: 40px;
		background: rgba($red, 0.9);
		color: $white;
		font-size: $xxxl;
	}
}