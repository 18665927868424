@charset "utf-8";
/* ============================================================ */
/* header */
/* ============================================================ */
header {
	width: 100%;
	background: url(/common/img/common/bg_gray.png);
	
	.header-top {
		background: $baseColor;
		height: 30px;
		
		&__inner {
			display: flex;
			align-items: center;
			padding: 0;
			color: $white;
			max-width: 1000px;
			margin: 0 auto;
			line-height: 30px;
			
			&__item {
				display: flex;
				
				a {
					color: $white;
				}
				
				li:first-child {
					margin-right: 20px;
				}
			}
		}
	}
	
	.header__inner {
		display: flex;
		max-width: 1000px;
		height: 95px;
		margin: 0 auto;
		padding-top: 20px;
		justify-content: space-between;
		
		& > * {
			display: flex;
		
			& > * {
				margin-right: 20px;
			}
		}
	}
	
	.header-logo {
		flex: 0 1 230px;
	}
	
	.header-link {
		a {
			color: #a67c52;
		}
	}
	
	.header-credit {
		background: $baseColor;
		border: 2px solid $baseColor;
		border-radius: 3px;
		text-align: center;
		color: #d1b486;
		line-height: 30px;
		height: 69px;
		
		&__item {
			margin-bottom: 0;
			padding: 0 10px;
			
			&--text {
				line-height: 35px;
				background: #efe7dc;
				border-radius: 0 0 3px 3px;	
				font-size: 1.6rem;
				color: $baseColor;
			}
		}		
	}
	
	.header-contact {
		margin-right: 0;
		&__item {
			margin-bottom: 0px;
			text-align: center;
			line-height: 1.2;
			
			&--tel {
				font-size: 3.0rem;
				font-weight: bold;
				
				i {
					font-size: 2.6rem;
				}
			}
		}
	}
	
	.btn {
		box-shadow: 0px 6px 0px rgba(135,24,24,1);
		font-size: 1.5rem;
		height: 55px;
	}
	.gnav {
		display: flex;
		max-width:1000px;
		justify-content:space-between;
		align-items: center;
		height: 50px;
		margin: 0 auto;
		padding: 0;
		
		a {
			color: $baseColor;
		}
	
		&__item {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			border-left: 1px solid #c9b49f;
			text-decoration: none;
			text-align: center;
			width: 100%;
			font-size: 1.5rem;
			line-height: 1.2;
			font-family: $ryumin;
			transform: rotate(0.05deg);

			&:last-child {
				border-right: 1px solid #c9b49f;
			}
			
			&__text {
				&:hover {
					opacity: 0.7;
				}
			}
		}
	}
}