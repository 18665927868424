@charset "utf-8";
/* ============================================================ */
/* footer */
/* ============================================================ */
footer {
	line-height: 1.6;
	background: #1a1a1a;
	border-bottom: 1px solid #534741;
	
	.footer-inner {
		width: 100%;
		margin: 0 auto;
		
		.footer-logo {
			margin: 20px 0;
			width: 100%;
			
			&__link {
				display: block;
				width: 160px;
				margin: 0 auto;
			}
			&__address {
				text-align: center;
				color: $white;
				margin-top: 10px;
			}
		}
		
		.footer-sitemap {
			width: 100%;
			margin-bottom: 0;
			
			&__contents {
				border-right: 1px solid #534741;
				margin-bottom: 0;
				
				&:last-child {
					border-right: none;
					margin-right: 0;
				}
				
				&__item {
					font-size: 1.3rem;
					padding: 10px 15px;
					border-top: 1px solid #534741;
					color: #ddd7d0;
					width: 100%;
					display: block;
				}
				
				a {
					color: #ddd7d0;
					display: block;
				}
			}
		}
	}
}
.copyright {
	padding: 15px 0;
	background: #1a1a1a;
	color: #ddd7d0;
	font-size: $xxs;
	text-align: center;
}