@charset "utf-8";
/* --------------------------------------------------- */
/* point */
/* --------------------------------------------------- */
.point {
	&__list {
		padding: 35px 40px 20px 40px;
		a {
			text-decoration: underline;
			&:hover {
				color: $textColor;
			}
		}
	}
}