@charset "utf-8";
/* ============================================================ */
/* .contents */
/* ============================================================ */
.contents {
	position: relative;
}


/* ============================================================ */
/* 汎用スタイル */
/* ============================================================ */

.bg--gray {
	background: url(/common/img/common/bg_gray.png) repeat;
}
.bg--orange {
	background: url(/common/img/common/bg_orange.png) repeat;
	background-size: cover;
}
.bg--white {
	background: url(/common/img/common/bg_white.png);
	background-size: cover;
}
.bg--red {
	background: url(/common/img/common/bg_red.png) repeat;
}

.border-color--brown {
	border-bottom: 1px solid $borderColor;
}

.header-img {
	max-height: 500px;
	overflow: hidden;
}

/* --------------------------------------------------- */
/* テキスト */
/* --------------------------------------------------- */
p {
	margin-bottom: 15px;
	
	&:last-child {
		margin-bottom: 0;
	}
}

.indent {
	display: table;
	
	& > *:first-child {
		display: table-cell;
		white-space: nowrap;
	}
}


/* --------------------------------------------------- */
/* 見出し */
/* --------------------------------------------------- */
.ttl01 {
	position: relative;
	display: inline-block;
	padding-bottom: 15px;
	color: $red;
	font-size: 2.8rem;
	margin-bottom: 30px;	
	&:before {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		border-bottom: 3px double $red;
		width : 80% ;
		width : -webkit-calc(100% - 40px) ;
		width : calc(100% - 40px) ;
		margin: 0 auto;
	}
}

.ttl02 {
	text-align: center;
	color: $baseColor;
	font-size: 2.8rem;
	font-weight: bold;
	line-height: 1.2;
	margin-bottom: 30px;
	&:after {
		display: block;
		content: url(/common/img/common/bg_title_01.png);
	}
}

.ttl03 {
	margin: 0 0 20px;
	font-size: 3.0rem;
	font-weight: bold;
	line-height: 1.4;
	
	i {
		font-size: 2.6rem;
	}
}

.ttl04 {
	position: relative;
	margin: 0 0 10px;
	padding: 0 0 15px;
	border-bottom: 1px solid #c7b299;
	line-height: 0.8;
	font-size: 2.0rem;
	
	&::after {
		position: absolute;
		left: 0;
		bottom: -1px;
		content: '';
		width: 115px;
		border-bottom: 1px solid $red;
	}
	
	&:before {
		content: url(/common/img/common/ico_01.png);
		margin-right: 0.3em;
		position:relative;
		top: 4px;
	}
}

/* --------------------------------------------------- */
/* カラム制御 */
/* --------------------------------------------------- */

/* 複数カラム
/* --------------------------------------------------- */
.column {
	display: flex;
	flex-wrap: wrap;
}

.column > * {
	margin-bottom: 15px;

	* {
		max-width: 100%;
	}

	img {
		outline: 1px solid transparent;
		
		&:not([class*="wd-a"]) {
			width: 100%;
		}
	}
}

.column1 {
	&> * {
		width: 100%;
	}
}

.column2 {
	 margin-left: -5px;
	 margin-right: -5px;

	 &> * {
		 margin-left: 5px;
		 margin-right: 5px;
		 width: calc((100% - 21px) / 2 );
	 }
 }

.column3 {
	margin-left: -10px;
	margin-right: -10px;

	&> * {
		margin-left: 10px;
		margin-right: 10px;
		width: calc((100% - 61px) / 3);
	}
}

.column4 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 23%;
	}
}

.column5 {
	margin-left: -1%;
	margin-right: -1%;

	&> * {
		margin-left: 1%;
		margin-right: 1%;
		width: 18%;
	}
}


/* 幅指定カラム
------------------------------------------------------------ */
.flex-column {
	display: flex;
	justify-content: space-between;

	&> * {
		flex: 0 0 auto;

		* {
			max-width: 100%;
		}

	}
}

/* --------------------------------------------------- */
/* リスト */
/* --------------------------------------------------- */
.list {
	list-style-type: none;

	& > * {
		position: relative;
		padding-left: 15px;
		margin-bottom: 5px;

		&::before {
			content: "";
			width: 6px;
			height: 6px;
			display: block;
			position: absolute;
			top: 0.7em;
			left: 0;
		}
	}

	&--circle {
		& > * {
			&::before {
				width: 6px;
				height: 6px;
				border-radius: 100%;
			}
		}
	}
	
	&--black {
		& > * {
			&::before {
				background: $textColor;
			}
		}
	}

	&--brown {
		& > * {
			&::before {
				background: $baseColor;
			}
		}
	}

	&--decimal {
		list-style: decimal outside;
		margin: 0 0 15px 20px;
		& > * {
			padding-left: 0;
		}
	}
}


/* --------------------------------------------------- */
/* box */
/* --------------------------------------------------- */
.box {
	padding: 25px;
	box-sizing: border-box;

	&--border-brown {
		border: 1px solid $borderColor;
	}
}

/* --------------------------------------------------- */
/* table */
/* --------------------------------------------------- */
.table {
	width: 100%;
	
	th,
	td {
		padding: 10px 15px;
		background: $white;
		border: 1px solid #b2937d;
		text-align: left;
		vertical-align: middle;
		line-height: 1.6;
	}
	.bg-brown {
		background: $brown;
		color: $white;
	}
	.bg-beige {
		background: $beige;
	}
}


/* --------------------------------------------------- */
/* btn */
/* --------------------------------------------------- */
.btn {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 150px;
	margin: 0 5px;
	padding: 10px 30px;
	color: $white;
	font-size: $m;
	text-align: center;
	line-height: 1.4;
	cursor: pointer;
	font-family: $ryumin;
	transform: rotate(0.05deg);
	
	.icon-chevron-right,
	.icon-chevron-down {
		right: 15px;
		font-size: $xs;
		@include centering-elements(false, true);
	}
	&:hover {
		opacity: 0.7;
		text-decoration: none;
	}
	
	&--beige {
		background: #b78d63;
	}
	&--black {
		background: #1a1a1a;
	}
	&--red {
		background: $red;
	}
	&--pattern01 {
		background: url(/common/img/common/bg_red.png) repeat;
		padding: 0 5px;
		& > * {
			border-left: 1px solid #c6a66d;
			padding: 15px 0px;
			min-width: 300px;
		}
	}
	&--lg {
		min-width: 300px;
		padding: 15px 70px;
	}
	&--m {
		min-width: 240px;
	}
	&--sm {
		min-width: inherit;
		height: 40px;
	}
}


/* --------------------------------------------------- */
/* pager */
/* --------------------------------------------------- */
.pager {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 30px 0 0;
	
	li {
		margin: 0 3px 5px;

		& > * {
			display: block;
			padding: 10px 15px;
			background: #f3f3f3;
			border: 1px solid #d5d5d5;
			color: $textColor;
			@include radius(3);
			
			&.current,
			&:hover {
				background: $red;
				border: 1px solid $red;
				color: $white;
				opacity: 1;
				text-decoration: none;
			}
		}
	}
	
	&__prev {
		position: absolute;
		left: 0;
	}
	&__next {
		position: absolute;
		right: 0;
	}
}


/* --------------------------------------------------- */
/* accordion */
/* --------------------------------------------------- */
.accordion {
	position: relative;
	padding: 10px 30px 10px 10px;
	cursor: pointer;
	line-height: 1.6;
	
	&:hover {
		opacity: 0.7;
	}
	
	&__icon {
		right: 10px;
		color: $baseColor;
		@include centering-elements(false, true);
	}
	&.active {
		.accordion__icon::before {
			display: block;
			transform: rotate(180deg);
		}
	}
}
.accordion-hide {
	display: none;
	padding-bottom: 25px;
}

/* --------------------------------------------------- */
/* snavi */
/* --------------------------------------------------- */
.snavi {
	.btn--pattern01 {
		margin: 0;
		width: 100%;
		& > * {
			width: 100%;
			min-width: auto;
		}
	}
}

/* --------------------------------------------------- */
/* menu */
/* --------------------------------------------------- */
.menu {
	&__item {
		border-bottom: 1px solid $borderColor;
		padding: 15px 0 5px;
		display: flex;
		justify-content: space-between;
		font-size: 1.6rem;
		transform: rotate(0.05deg);
		
		&__text {
			width: 150px;
			display: inline-block;
			text-align: right;
		}
	}
	
	table {
		transform: rotate(0.05deg);
		font-size: 1.6rem;
		width: 100%;
		margin-top: 20px;
		thead {
			th {
				background: #ddd3cc;
				padding: 5px 10px;
				border-left: 3px solid #f7f5f1;
				
				&:first-child {
					border-left: none;
				}
			}
		}
		tbody {
			td {
				border-bottom: 1px solid $borderColor;
				padding: 10px 10px 5px;
				vertical-align: middle;
				text-align: center;
			}
		}
	}
}
