@charset "utf-8";
/* ============================================================ */
/* /news/ , /blog/ , /tournament/ */
/* ============================================================ */
.news__list {
	padding: 10px 0px 20px;
	&__item {
		padding: 15px 0;
		border-bottom: 1px dashed $borderColor;
		line-height: 1.6;
		
		&:first-child {
			padding: 0 0 15px;
		}
		
		a {
			color: $textColor;
		}
		img {
			float: left;
			margin-right: 10px;
		}
		
		&--time {
			color: $baseColor;
			display: block;
		}
	}
}

.news__category {
	&__title {
		background: $baseColor;
		color: $white;
		padding: 10px 30px;
	}
	
	&__contents {
	
		&__item {
			margin-bottom: 5px;
			a {
				color: $textColor;
				text-decoration:underline;
			}
		}
	}
}


.news__view {
	&__date {
		padding: 20px 0 10px;
		
		label {
			margin-left: 20px;
		}		
	}
	
	&__content {
		strong {
			font-weight: bold!important;
			color: $textColor!important;
		}
		
		p {
			margin: 0 0 30px;
		}
		
		img {
			max-width: 100%!important;
			height: auto!important;
			margin-bottom: 20px;
		}
	}
	&__comment {
		margin-top: 30px;
		border-top: 1px solid $borderColor;
		padding: 20px 0;
		
		a {
			text-decoration: underline;
		}
		
		&__form {
			margin-top: 30px;
			input[type="text"],textarea {
				border: 1px solid $borderColor;
				padding: 5px 10px;
			}
		}
	}
}