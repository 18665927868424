@charset "utf-8";
/* --------------------------------------------------- */
/* point */
/* --------------------------------------------------- */
.point {
	&__list {
		padding: 15px 10px 5px 10px;
		a {
			text-decoration: underline;
		}
	}
}