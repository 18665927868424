@charset "utf-8";
/* ============================================================ */
/* footer */
/* ============================================================ */
footer {
	line-height: 1.6;
	background: #1a1a1a;
	border-bottom: 1px solid #534741;
	
	.footer-inner {
		max-width: 1000px;
		margin: 0 auto;
		
		.footer-logo {
			margin: 40px 0;
			width: 45%;
			
			&__link {
				display: block;
				width: 160px;
			}
			&__address {
				color: $white;
				margin-top: 10px;
			}
		}
		
		.footer-sitemap {
			margin: 40px 0;
			width: 55%;
			
			&__contents {
				border-right: 1px solid #534741;
				
				&:last-child {
					border-right: none;
					margin-right: 0;
				}
				
				&__item {
					color: #ddd7d0;
					margin-bottom: 10px;
					transform: rotate(0.05deg);
					&:before {
						content:"-";
						margin-right: 5px;
					}
				}
				
				a {
					color: #ddd7d0;
				}
			}
		}
	}
}
.copyright {
	padding: 15px 0;
	background: #1a1a1a;
	color: #ddd7d0;
	font-size: $xxs;
	text-align: center;
}