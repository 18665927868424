@charset "utf-8";
/* ============================================================ */
/* header */
/* ============================================================ */
header {
	width: 100%;
	display: flex;
	background: url(/common/img/common/bg_gray.png);
	
	.header__inner {
		align-items: center;
		height: 50px;
		padding: 0 15px;
		flex: 1 1 auto;
	}

	.header-logo {
		display: block;
		width: 120px;
		padding-top: 5px;
	}
	
	.tel {
		width: 50px;
		height: 50px;
		position: absolute;
		font-size: 2.2rem;
		text-align: center;
		right: 50px;
		top: 0;
		background: $red;
		padding: 2px 0 0;
	
		span {
			font-size: 0.9rem;
			display: block;
			line-height: 1;
			margin: -5px 0 0;
		}
		a {
			color: $white;	
		}
	}
	
	.header-menu,
	.header-menu span {
		@include transition;
	}

	.header-menu {
		position: relative;
		width: 50px;
		height: 50px;
		z-index: 10;
		cursor: pointer;
		background: $baseColor;

		&__item {
			display: inline-block;
			position: absolute;
			left: 20%;
			width: 60%;
			height: 3px;
			background: $white;
			@include radius(2);

			&:nth-of-type(1) { top: 9px; }
			&:nth-of-type(2) { top: 17px; }
			&:nth-of-type(3) { top: 25px; }
			
			&--text {
				display: inline-block;
				position: absolute;
				font-size: 9px;
				color: $white;
				text-align: center;
				bottom: 2px;
				left: 5px;
			}
		}
		&.active {
			.header-menu__item {
				&:nth-of-type(1) { transform: translateY(10px) rotate(-315deg); top:15px; }
				&:nth-of-type(2) { opacity: 0; }
				&:nth-of-type(3) { transform: translateY(-10px) rotate(315deg); top:35px; } 
				&--text { opacity: 0; }
			}
		}
	}

	.gnav {
		position: fixed;
		left: 0;
		top: 0;
		z-index: -1;
		width: 100%;
		height: 100%;
		padding: 50px 15px 15px;
		background: rgba(#000, 0.85);
		opacity: 0;
		@include transition;

		&__item {
			display: flex;
			align-items: center;
			padding: 10px 10px;
			border-bottom: 1px solid rgba($white, 0.2);
			color: $white;
			font-size: $m;
			
			&:first-child {
				border-top: 1px solid rgba($white, 0.2);
			}
			.icon-angle-right {
				margin: 0 10px 0 0;
				color: $white;
			}
		}
		
		.show & {
			z-index: 5;
			opacity: 1;
		}
	}
}