@charset "utf-8";
/* --------------------------------------------------- */
/* company */
/* --------------------------------------------------- */
.company {
	.introduction {
		margin-top: 30px!important;
		.ttl04 {
			display: none;
		}
	}
}
