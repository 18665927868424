@charset "utf-8";
/* --------------------------------------------------- */
/* faq */
/* --------------------------------------------------- */
.faq {
	&__list {
		border-bottom: 1px solid $borderColor;
		
		.accordion {
			border-top: 1px solid $borderColor;
			padding: 20px 30px 20px 0;
			position: relative;
			color: $red;
			transform: rotate(0.05deg);
			font-size: 1.8rem;
			
			&:first-child {
				border-top: none;
			}
			
			&::before {
				position: absolute;
				@include centering-elements(false, true);
				display: flex;
				justify-content: center;
				align-items: center;
				content: "Q";
				margin: 0 20px 0 0;
				width: 35px;
				height: 35px;
				background: $red;
				color: $white;
				font-size: $m;
				@include circle(35);
			}
		}
		
		.accordion-hide {
			position: relative;
			&::before {
				position: absolute;
				display: flex;
				top: 5px;
				justify-content: center;
				align-items: center;
				content: "A";
				margin: 0 20px 0 0;
				width: 35px;
				height: 35px;
				background: #b58d7d;
				color: $white;
				font-size: $m;
				@include circle(35);
			}
		}
	}
}